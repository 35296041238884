const getPhone = (city: string | null) => {
    switch (city) {
        case 'Москва':
            return '8 (495) 646-87-67';
        case 'Санкт-Петербург':
            return '8 (812) 648-24-33';
        case 'Новосибирск':
            return '8 (383) 233-39-09';
        case 'Екатеринбург':
            return '8 (343) 288-51-59';
        case 'Краснодар':
            return '8 (861) 202-54-16';
        case 'Самара':
            return '8 (846) 212-95-39';
        case 'Красноярск':
            return '8 (391) 228-70-27';
        case 'Пермь':
            return '8 (342) 235-79-96';
        case 'Казань':
            return '8 (843) 210-09-66';
        case 'Ростов-на-Дону':
            return '8 (863) 303-21-99';
        case 'Воронеж':
            return '8 (473) 300-30-87';
        case 'Уфа':
            return '8 (347) 200-06-87';
        case 'Волгоград':
            return '8 (844) 296-21-60';
        case 'Челябинск':
            return '8 (351) 200-33-36';
        case 'Нижний Новгород':
            return '8 (831) 280-82-73';
        default:
            return '8 (800) 600 1141';
    }
};

export default getPhone;
