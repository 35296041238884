import {
    APIDeliveryCountriesResponse,
    APIDeliveryCountriesResponseItem,
} from '@api/types/delivery/countries';
import { APIDeliveryGeoTextResponse } from '@api/types/delivery/geoText';
import { APIGeoIPResponse } from '@api/types/geo/ip';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import phone_mapping from '@src/data/phone_mapping.json';
import { ApiMiddleware } from '@vsemayki/shared-frontend';
import { findKey } from 'lodash';
import { AppThunkAction, RootState } from '.';
import { userSelector, getUserIP } from './application/user';
import SHARED_CONSTANTS from '@utils/shared_constants';
import getPhone from '@src/utils/cityPhones';

const { apiRequest } = ApiMiddleware;

export type CountryItem = Omit<APIDeliveryCountriesResponseItem, 'title'> & {
    suggest: string[];
    title: string;
};

export interface GeoState {
    autocompleteOptions: {
        id: string;
        label: string;
        region: string;
        country: string;
    }[];
    city: string;
    country: CountryItem;
    region: string;
    phone: string;
    countries: CountryItem[];
    popularCities: {
        label: string;
        region: string;
        phone: string;
        domain: string;
    }[];
    showConfirmCity: boolean;
    deliverySuggestIsLoading: boolean;
}

const initialState: GeoState = {
    autocompleteOptions: [],
    city: '',
    country: {
        active: false,
        allow_russian: true,
        id: 0,
        iso_numeric: 0,
        name: '',
        name_alt: null,
        name_en: null,
        name_official: null,
        position: 0,
        split_type: null,
        suggest: [],
        title: 'Россия',
        iso_alphabetic: 'RU',
    },
    region: '',
    phone: '8 (800) 600 1141',
    countries: [],
    popularCities: [
        {
            label: 'Москва',
            region: 'Москва г',
            phone: '8 (495) 646-87-67',
            domain: 'www',
        },
        {
            label: 'Санкт-Петербург',
            region: 'Санкт-Петербург г',
            phone: '8 (812) 648-24-33',
            domain: 'www',
        },
        {
            label: 'Новосибирск',
            region: 'Новосибирская обл',
            phone: '8 (383) 233-39-09',
            domain: 'www',
        },
        {
            label: 'Екатеринбург',
            region: 'Свердловская обл',
            phone: '8 (343) 288-51-59',
            domain: 'www',
        },
        {
            label: 'Нижний Новгород',
            region: 'Нижегородская обл',
            phone: '8 (831) 280-82-73',
            domain: 'www',
        },
        { label: 'Омск', region: 'Омская обл', domain: 'www', phone: '' },
        {
            label: 'Ростов-на-Дону',
            region: 'Ростовская обл',
            phone: '8 (863) 303-21-99',
            domain: 'www',
        },
        {
            label: 'Пермь',
            region: 'Пермский край',
            phone: '8 (342) 235-79-96',
            domain: 'www',
        },
        {
            label: 'Волгоград',
            region: 'Волгоградская обл',
            phone: '8 (844) 296-21-60',
            domain: 'www',
        },
        {
            label: 'Уфа',
            region: 'Башкортостан Респ',
            phone: '8 (347) 200-06-87',
            domain: 'www',
        },
        {
            label: 'Красноярск',
            region: 'Красноярский край',
            phone: '8 (391) 228-70-27',
            domain: 'www',
        },
        {
            label: 'Казань',
            region: 'Татарстан Респ',
            phone: '8 (843) 210-09-66',
            domain: 'www',
        },
        {
            label: 'Самара',
            region: 'Самарскaя обл',
            phone: '8 (846) 212-95-39',
            domain: 'www',
        },
        {
            label: 'Челябинск',
            region: 'Челябинская обл',
            phone: '8 (351) 200-33-36',
            domain: 'www',
        },
        {
            label: 'Краснодар',
            region: 'Краснодарский край',
            phone: '8 (861) 202-54-16',
            domain: 'www',
        },
    ],
    showConfirmCity: true,
    deliverySuggestIsLoading: false,
};

const getDefaultsFromInitialState = () =>
    initialState.popularCities.find((city) => city.label.match('Москва')) ??
    initialState.popularCities[0];

const geo = createSlice({
    name: 'Geo',
    initialState,
    reducers: {
        setCountries: (state, action: PayloadAction<GeoState['countries']>) => {
            state.countries = action.payload;
        },
        setDeliverySuggestIsLoading: (
            state,
            action: PayloadAction<GeoState['deliverySuggestIsLoading']>
        ) => {
            state.deliverySuggestIsLoading = action.payload;
        },
        setAutocompliteOptions: (
            state,
            action: PayloadAction<GeoState['autocompleteOptions']>
        ) => {
            state.autocompleteOptions = action.payload;
        },
        setShowConfirmCity: (
            state,
            action: PayloadAction<GeoState['showConfirmCity']>
        ) => {
            state.showConfirmCity = action.payload;
        },
        setPhone: (state, action: PayloadAction<GeoState['phone']>) => {
            state.phone = action.payload;
        },
        setCity: (
            state,
            action: PayloadAction<GeoState['city'] & { domain?: string }>
        ) => {
            state.city = action.payload;
        },
        setRegion: (state, action: PayloadAction<GeoState['region']>) => {
            state.region = action.payload;
        },
        setCountry: (state, action: PayloadAction<GeoState['country']>) => {
            state.country = action.payload;
        },
        setFallback: (state) => {
            const defaults = getDefaultsFromInitialState();
            return {
                ...initialState,
                //remain countries that we get, if need full reset state create a new action;
                countries: state.countries,
                city: defaults.label,
                region: defaults.region,
            };
        },
    },
});

export const geoActions = geo.actions;
export default geo.reducer;

export const geoSelector = (state: RootState) => state.geo;

export const getCountries: AppThunkAction = () => async (
    dispatch,
    getState
) => {
    try {
        const State = getState();
        const GeoState = geoSelector(State);
        const countriesInRedux = GeoState.countries;

        if (countriesInRedux.length > 0) {
            return;
        }

        const isString = <TValue>(value: TValue | null): value is TValue => {
            return value !== null;
        };

        const countries: GeoState['countries'] = await dispatch<
            APIDeliveryCountriesResponse
        >(apiRequest({ url: '/rest/delivery/countries' })).then((result) =>
            result
                .filter((country) => country.active)
                .sort((a, b) => a.position - b.position)
                .map((item) => ({
                    ...item,
                    title: item.name,
                    suggest: [
                        item.name,
                        item.title,
                        item.name_en,
                        item.name_alt,
                        item.name_official,
                    ]
                        .filter(isString)
                        .map((item) => item.toLowerCase()),
                }))
        );

        dispatch(geoActions.setCountries(countries));
    } catch (error) {
        console.error(error);
    }
};

export const setDefaultCountryFromCountries: AppThunkAction<
    GeoState['countries'],
    void
> = (countries) => (dispatch, getState) => {
    const State = getState();
    const GeoState = geoSelector(State);

    if (GeoState.country.id) return;

    const defaultCountry = countries.find((country) =>
        country.name.toLowerCase().match('россия')
    );

    if (!defaultCountry) return;

    dispatch(geoActions.setCountry(defaultCountry));
};

export const citySuggestSearch: AppThunkAction<string> = (address) => async (
    dispatch
) => {
    try {
        dispatch(geoActions.setDeliverySuggestIsLoading(true));
        if (!address) {
            dispatch(geoActions.setAutocompliteOptions([]));
            return;
        }

        const matches = await dispatch<APIDeliveryGeoTextResponse>(
            apiRequest({ url: `/rest/delivery/geo?text=${address}` })
        );

        const autocompleteOptions = matches.map((item) => ({
            id: item.info.uid,
            label: item.match,
            region: item.info.region,
            country: 'Россия',
        }));

        dispatch(geoActions.setAutocompliteOptions(autocompleteOptions));
    } catch (e) {
        dispatch(geoActions.setDeliverySuggestIsLoading(false));
    }
};

export const checkLocation: AppThunkAction = () => async (
    dispatch,
    getState
) => {
    try {
        if (
            !process.browser ||
            localStorage.getItem(SHARED_CONSTANTS.localStorage.geoIsLoaded)
        )
            return;

        await dispatch(getCountries());

        const State = getState();
        const GeoState = geoSelector(State);
        const UserState = userSelector(State);

        if (!UserState.ip) {
            await dispatch(getUserIP());
        }

        //1. get IP;
        const ip = userSelector(getState()).ip;

        const GeoIPReponse = await dispatch<APIGeoIPResponse>(
            apiRequest({ url: `rest/geo/ip?ip=${ip}` })
        );

        if ('error' in GeoIPReponse) {
            throw new Error('cant get user by ip');
        }

        const country = GeoState.countries.find(
            (item) => item.title === GeoIPReponse.country
        );

        //2. set country from IP or default coutnry;
        if (country) {
            dispatch(geoActions.setCountry(country));
        } else {
            dispatch(setDefaultCountryFromCountries(GeoState.countries));
        }

        //3. if responce have city (name);
        if (GeoIPReponse.name) {
            dispatch(geoActions.setCity(GeoIPReponse.name));
        }

        dispatch(geoActions.setRegion(GeoIPReponse.area));

        const locationParts = window.location.hostname.split('.');
        const subdomain = locationParts.length === 3 ? locationParts[0] : 'www';

        const subdomainCity = findKey(
            phone_mapping,
            (item) => item.subdomain === subdomain
        );

        //4. if we found subdomain then set it and dont care if we overwrite.
        if (subdomainCity) {
            dispatch(geoActions.setCity(subdomainCity));
        }

        dispatch(geoActions.setPhone(getPhone(GeoIPReponse.name)));

        localStorage.setItem(SHARED_CONSTANTS.localStorage.geoIsLoaded, '1');

        //5.Check up that all correct
        const SettedState = getState();
        const SettedGeoState = geoSelector(SettedState);

        if (SettedGeoState.country.title === 'Россия') {
            if (!SettedGeoState.city) {
                const defaultState = getDefaultsFromInitialState();
                dispatch(geoActions.setCity(defaultState.label));
                dispatch(geoActions.setRegion(defaultState.region));
            }
        } else {
            if (SettedGeoState.city === null) {
                dispatch(geoActions.setCity(''));
            }
        }
    } catch (error) {
        console.error('checkLocation error: ', error);
        dispatch(geoActions.setFallback());
    }
};
