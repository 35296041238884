import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunkAction } from '..';
import Bower from 'bowser';
import { ApiMiddleware } from '@vsemayki/shared-frontend';
const { apiRequest } = ApiMiddleware;

export type UserState = {
    platform: 'desktop' | 'mobile';
    isLoading: boolean;
    ip: string;
    id: number;
};

const initialState: UserState = {
    platform: 'desktop',
    isLoading: false,
    ip: '',
    id: 0,
};

const user = createSlice({
    name: 'User',
    initialState,
    reducers: {
        setLoading: (state, action: PayloadAction<UserState['isLoading']>) => {
            state.isLoading = action.payload;
        },
        setPlatform: (state, action: PayloadAction<UserState['platform']>) => {
            state.platform = action.payload;
        },
        setIP: (state, action: PayloadAction<UserState['ip']>) => {
            state.ip = action.payload;
        },
    },
});

export const userActions = user.actions;
export default user.reducer;

export const userSelector = (state: RootState) => state.application.user;
export const userPlatformSelector = (state: RootState) =>
    userSelector(state).platform;

export const getPlatformFromUserAgent: AppThunkAction<string> = (
    userAgent
) => async (dispatch) => {
    const userAgentParsed = Bower.parse(userAgent);
    dispatch(
        userActions.setPlatform(
            userAgentParsed.platform.type === 'desktop' ? 'desktop' : 'mobile'
        )
    );
};

export const getUserIP: AppThunkAction = () => async (dispatch) => {
    try {
        const UserResponse = await dispatch<{ ip: string }>(
            apiRequest({ url: '/user' })
        );
        dispatch(userActions.setIP(UserResponse.ip));
    } catch (error) {
        console.error('cant get user', error);
    }
};
