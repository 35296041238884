import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '..';

export type loadingState = boolean;

const initialState = false;
const loading = createSlice({
    name: 'Loading',
    initialState,
    reducers: {
        setLoading: (_state, action: PayloadAction<loadingState>) =>
            action.payload,
    },
});

export const loadingActions = loading.actions;
export default loading.reducer;

export const loadingSelector = (state: RootState) => state.application.loading;
